import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Grid, Typography, Box, Button, Container } from "@material-ui/core"

import Layout from "../components/layout"
import Heading from "../components/heading"
import TrainingModal from "../components/modals/training.js"

import raid from "../images/training/raid.svg"
import rootCause from "../images/training/root.svg"
import sigma from "../images/training/sigma.svg"
import pat from "../images/training/pat.svg"
import kpi from "../images/training/kpi.svg"
import idea from "../images/training/idea.svg"
import pda from "../images/training/document.svg"
import excel from "../images/training/excel.svg"
import python from "../images/training/python.svg"
import database from "../images/training/database.svg"

function Training() {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const query = useStaticQuery(graphql`
    query {
      seo: strapiSeo {
        trainingPage {
          pagetitle
          description
          keywords
        }
      }
      training: allStrapiTraining {
        edges {
          node {
            RAPID_IMPROVEMENT_DESIGN
            DATABASE_TRAINING
            EXCEL_SKILL_ENHANCEMENT
            IDEATION_WORKSHOPS
            KPI_IDENTIFICATION_AND_MONITORING
            PROCESS_AUTOMATABILITY_TEST
            PROCESS_DOCUMENTATION_APPROACH
            PYTHON_BASICS
            ROOT_CAUSE_ANALYSIS
            SIX_SIGMA
          }
        }
      }
    }
  `)

  return (
    <Layout
      siteTitle={query.seo.trainingPage.pagetitle ?? "Training"}
      description={query.seo.trainingPage.description ?? ""}
      keywords={query.seo.trainingPage.keywords ?? ""}
    >
      <TrainingModal handleClose={handleClose} open={open} />
      <Heading title="Consulting & Training" />

      <Box py={5}>
        <Container maxWidth="lg">
          <Grid container justify="space-evenly">
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={raid} alt="raid" height="100px" />
                </Box>
                <Typography variant="h6">
                  <b>RA</b>pid <b>I</b>mprovement <b>D</b>esign
                </Typography>
                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {query.training.edges[0].node.RAPID_IMPROVEMENT_DESIGN}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={rootCause} alt="rootCause" height="100px" />
                </Box>
                <Typography variant="h6">
                  <b>R</b>oot <b>C</b>ause <b>A</b>nalysis
                </Typography>
                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {query.training.edges[0].node.ROOT_CAUSE_ANALYSIS}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={sigma} alt="sigma" height="80px" />
                </Box>
                <Typography variant="h6">
                  <b>S</b>ix <b>S</b>igma
                </Typography>

                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {query.training.edges[0].node.SIX_SIGMA}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={pat} alt="pat" height="100px" />
                </Box>
                <Typography variant="h6">
                  <b>P</b>rocess <b>A</b>utomatability <b>T</b>est
                </Typography>

                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {query.training.edges[0].node.PROCESS_AUTOMATABILITY_TEST}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={kpi} alt="kpi" height="100px" />
                </Box>
                <Typography variant="h6">
                  <b>KPI</b> <b>I</b>dentification and <b>M</b>onitoring
                </Typography>

                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {
                      query.training.edges[0].node
                        .KPI_IDENTIFICATION_AND_MONITORING
                    }
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={idea} alt="idea" height="100px" />
                </Box>
                <Typography variant="h6">
                  <b>I</b>deation <b>W</b>orkshops
                </Typography>

                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {query.training.edges[0].node.IDEATION_WORKSHOPS}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={pda} alt="pda" height="100px" />
                </Box>
                <Typography variant="h6">
                  <b>P</b>rocess <b>D</b>ocumentation <b>A</b>pproach
                </Typography>

                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {
                      query.training.edges[0].node
                        .PROCESS_DOCUMENTATION_APPROACH
                    }
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={excel} alt="excel" height="100px" />
                </Box>
                <Typography variant="h6">
                  <b>E</b>xcel <b>S</b>kill <b>E</b>nhancement
                </Typography>

                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {query.training.edges[0].node.EXCEL_SKILL_ENHANCEMENT}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={python} alt="python" height="100px" />
                </Box>
                <Typography variant="h6">
                  <b>P</b>ython <b>B</b>asics
                </Typography>

                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {query.training.edges[0].node.PYTHON_BASICS}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container justify="center">
              <Box
                textAlign="center"
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  borderRadius="30px"
                  height="180px"
                  width="180px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                  boxShadow={3}
                >
                  <img src={database} alt="database" height="100px" />
                </Box>
                <Typography variant="h6">
                  <b>D</b>atabase <b>T</b>raining
                </Typography>

                <Box px={4} pt={2}>
                  <Typography variant="subtitle1">
                    {query.training.edges[0].node.DATABASE_TRAINING}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box textAlign="center">
            <Box py={3}>
              <Typography variant="h5" color="primary">
                <b>For Training / Consulting</b>
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleOpen}
            >
              CONTACT US
            </Button>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default Training
